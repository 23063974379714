import React from 'react';

import PaypalStyled from './paypal.styled';

const Paypal = (props: { embedCode?: string }) => {
  if (!props.embedCode) return null;
  return <PaypalStyled dangerouslySetInnerHTML={{ __html: props.embedCode }} />;
};

export default Paypal;
