import styled, { css } from 'styled-components';
import { mq } from '~/theme/patterns';
import validateProps from '~/utils/validateProps';

const PaypalStyled = styled.div<{
  theme?: object;
}>`
  ${() => {
    const props = {};

    validateProps('PaypalStyled', { ...props });

    return css`
      border-top: 1px solid #ddd;
      margin-top: 2.4rem;
      padding-top: 1.6rem;

      table {
        width: 100%;

        tr:first-child {
          display: none;
        }
      }

      select {
        width: 100%;
        height: 4rem;
        border: 1px solid #ddd;
        max-width: 45rem;

        @media ${mq.medium} {
          max-width: 100%;
        }
      }

      input[type='image'] {
        margin-top: 1.6rem;
      }

      img {
        width: 1px;
      }
    `;
  }}
`;

export default PaypalStyled;
