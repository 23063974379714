import styled, { css } from 'styled-components';
import { spacer } from '~/theme/patterns';
// import { mq } from '~/theme/patterns';
import validateProps from '~/utils/validateProps';

const ContentStyled = styled.div<{
  theme?: object;
}>`
  ${({ theme }) => {
    const props = {
      title: theme?.typeStyles?.h1,
    };

    validateProps('ContentStyled', { ...props });

    return css`
      ${spacer('large', 'padding-top-bottom')}

      .title {
        ${props.title}
      }

      .image {
        ${spacer('medium')}
      }

      .content {
        ${spacer('medium')}
      }
    `;
  }}
`;

export default ContentStyled;
