import React from 'react';
import Image from '~/components/image';
import { Container } from '~/components/layout';
import Markup from '~/components/markup';
import Paypal from '~/components/paypal';
import { ContentTypes } from '~/schema/types';
import ContentStyled from './content.styled';

const Content = (props: { pageContext: ContentTypes.Content }) => {
  const { content, image, paypalEmbedCode, title } = props.pageContext;

  return (
    <ContentStyled>
      <Container narrow>
        <h1 className="title">{title}</h1>
        {image && (
          <div className="image">
            <Image {...image} />
          </div>
        )}
        <Markup className="content" html={content} />
        <Paypal embedCode={paypalEmbedCode} />
      </Container>
    </ContentStyled>
  );
};

export default Content;
